/**
 * Enable a secret mode that allows viewing the entire HTML content of the page
 * with a scrolling effect. Press Ctrl+Alt+H to enable or disable the mode.
 *
 * @returns void
 */
/**
 * Enable a secret mode that allows viewing the entire HTML content of the page
 * with a scrolling effect. Press Ctrl+Alt+H to enable or disable the mode.
 *
 * @returns void
 */
// Secret HTML Mode with "look inside" text link - with proper repeating scroll
function enableSecretHtmlMode() {
	// Scrolling variables
	let scrollInterval;
	let scrollPaused = false;
	const scrollSpeed = 1; // Pixels per frame

	// Create a toggle function
	const toggleSecretMode = () => {
		let existingContainer = document.getElementById("secret-html-container");

		if (existingContainer) {
			console.log("Disabling secret mode");
			// Turn off secret mode
			if (existingContainer.parentNode) {
				existingContainer.parentNode.removeChild(existingContainer);
			}

			const styleElement = document.getElementById("secret-mode-style");
			if (styleElement?.parentNode) {
				styleElement.parentNode.removeChild(styleElement);
			}

			// Clear any active scroll interval
			if (scrollInterval) {
				clearInterval(scrollInterval);
				scrollInterval = null;
			}

			return false;
		}
		console.log("Enabling secret mode");

		// Get the real HTML and format it to make it readable
		const formattedHtml = formatMinifiedHtml(
			document.documentElement.outerHTML,
		);

		// Repeat the content to ensure we have enough to scroll
		const repeatedHtml = `${formattedHtml}\n\n<!-- CONTENT REPEATS -->\n\n${formattedHtml}\n\n<!-- CONTENT REPEATS -->\n\n${formattedHtml}\n\n<!-- A NEW MESSAGE -->\n\n\n\n hello \n\n i am alive \n\n<!-- END MESSAGE -->`;

		// Create a container for scrolling
		const container = document.createElement("div");
		container.id = "secret-html-container";
		container.style.cssText = `
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          background-color: rgba(0, 0, 0, 0.85);
          z-index: -1;
          opacity: 0.4;
          transition: opacity 0.3s ease;
        `;

		// Create scrollable content
		const scrollableContent = document.createElement("pre");
		scrollableContent.id = "secret-html-background";
		scrollableContent.style.cssText = `
          white-space: pre;
          font-family: 'IBM Plex Mono', monospace;
          font-size: 12px;
          line-height: 1.5;
          color: #00ff00;
          padding: 20px;
          box-sizing: border-box;
          text-shadow: 0 0 2px rgba(0, 255, 0, 0.8);
          transform: translateY(0);
          transition: transform 0.2s linear;
        `;

		// Add the HTML content
		scrollableContent.innerHTML = repeatedHtml;
		container.appendChild(scrollableContent);

		// Add to the body as the first child
		document.body.insertBefore(container, document.body.firstChild);

		// Add custom style to make the content visible over the HTML background
		const originalContent = document.createElement("style");
		originalContent.id = "secret-mode-style";
		originalContent.textContent = `
          body {
            background: transparent !important;
          }
          
          /* Style main content to ensure it's still readable */
          body > div.mx-auto {
            position: relative;
            z-index: 1; /* Keep content above the HTML background */
          }
          
          /* Ensure other fixed elements stay above the HTML code */
          .domain-badge, #domain-tester, #look-inside-link, #online-indicator {
            z-index: 1000 !important;
          }
          
          /* Status indicator for scrolling */
          #secret-html-container::after {
            content: '⟳ Auto-scrolling';
            position: fixed;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            background-color: rgba(255, 192, 203, 0.7);
            color: black;
            padding: 5px 10px;
            border-radius: 20px;
            font-size: 12px;
            z-index: 999;
            opacity: 0;
            transition: opacity 0.5s ease;
          }
          
          #secret-html-container:hover::after {
            opacity: 1;
          }
          
          #secret-html-container.paused::after {
            content: '⏸️ Paused (click to resume)';
          }
        `;
		document.head.appendChild(originalContent);

		// Set up auto-scrolling
		scrollPaused = false;

		// Make container clickable to pause/resume scrolling
		container.addEventListener("click", (e) => {
			// Toggle pause state
			scrollPaused = !scrollPaused;

			// Update visual indicator
			if (scrollPaused) {
				container.classList.add("paused");
			} else {
				container.classList.remove("paused");
			}

			e.stopPropagation(); // Prevent clicks from reaching elements below
		});

		// Hover effects
		container.addEventListener("mouseenter", () => {
			container.style.opacity = "0.8";
			container.style.pointerEvents = "auto";
		});

		container.addEventListener("mouseleave", () => {
			container.style.opacity = "0.4";
			container.style.pointerEvents = "none";
		});

		// Wait for content to be fully loaded
		setTimeout(() => {
			// Get the content element
			const content = document.getElementById("secret-html-background");
			if (!content) return;

			// Get the content height (the scrollable area)
			const contentHeight = content.scrollHeight;
			console.log("Content height:", contentHeight);

			// Set up a variable to track scroll position
			let currentScroll = 0;

			// Start the auto-scrolling loop
			scrollInterval = setInterval(() => {
				if (!scrollPaused) {
					// Increment scroll position
					currentScroll += scrollSpeed;

					// Apply scroll with transform for better performance
					content.style.transform = `translateY(-${currentScroll}px)`;

					// When we've scrolled through a significant portion (1/3) of the content,
					// reset to the top to create endless scrolling effect
					if (currentScroll > contentHeight / 3) {
						// Reset to top
						currentScroll = 0;

						// Disable transition temporarily to avoid jump animation
						content.style.transition = "none";
						content.style.transform = "translateY(0)";

						// Force reflow to ensure the change takes effect
						void content.offsetHeight;

						// Re-enable transition for smooth scrolling
						content.style.transition = "transform 0.2s linear";
					}
				}
			}, 30); // Update interval
		}, 100); // Short delay to ensure content is loaded

		// Update link text
		const link = document.getElementById("look-inside-link");
		if (link) {
			link.textContent = "exit view";
		}

		return true;
	};

	// Function to format minified HTML to be readable
	function formatMinifiedHtml(html) {
		// Escape HTML to display as text
		const escapedHtml = html.replace(/</g, "&lt;").replace(/>/g, "&gt;");

		// Add line breaks at logical places
		let formatted = escapedHtml;

		// Add line breaks after closing tags
		formatted = formatted.replace(/&gt;/g, "&gt;\n");

		// Add line breaks before opening tags except for some inline tags
		const inlineTags = [
			"span",
			"a",
			"strong",
			"em",
			"small",
			"b",
			"i",
			"u",
			"sub",
			"sup",
		];
		const inlineTagsPattern = new RegExp(
			`&lt;(${inlineTags.join("|")})(\\s|&gt;)`,
			"g",
		);

		// First add line breaks before all tags
		formatted = formatted.replace(/&lt;(?!\/)([a-zA-Z])/g, "\n&lt;$1");

		// Then remove line breaks before inline tags (more complex, would require more advanced parsing)

		// Handle DOCTYPE separately
		formatted = formatted.replace(/&lt;!DOCTYPE/g, "\n&lt;!DOCTYPE");

		// Add proper indentation
		const lines = formatted.split("\n");
		let indent = 0;
		const indentSize = 2;
		const formattedLines = [];

		for (let i = 0; i < lines.length; i++) {
			let line = lines[i].trim();
			if (!line) continue; // Skip empty lines

			// Decrease indent for closing tags
			if (line.match(/^&lt;\/[a-zA-Z]/)) {
				indent = Math.max(0, indent - 1);
			}

			// Add indentation
			if (indent > 0) {
				const spaces = " ".repeat(indent * indentSize);
				line = spaces + line;
			}

			formattedLines.push(line);

			// Increase indent for opening tags if not self-closing or void
			if (
				line.match(/^&lt;[a-zA-Z]/) &&
				!line.match(/\/&gt;$/) &&
				!line.match(
					/^&lt;(img|br|hr|input|link|meta|source|track|wbr|area|base|col|embed|param|slot)/,
				)
			) {
				indent++;
			}
		}

		return formattedLines.join("\n");
	}

	// Set up a keyboard shortcut (Ctrl+Alt+H)
	let secretModeActive = false;
	document.addEventListener("keydown", (e) => {
		if (e.ctrlKey && e.altKey && e.key === "h") {
			secretModeActive = toggleSecretMode();
			updateLinkText(secretModeActive);
			e.preventDefault();
		}
	});

	/**
	 * Update the text of the "look inside" link based on the current state.
	 * @param {boolean} isActive
	 */
	function updateLinkText(isActive) {
		const link = document.getElementById("look-inside-link");
		if (link) {
			link.textContent = isActive ? "exit view" : "look inside";
		}
	}

	/**
	 * Create the "look inside" link and set up the click handler.
	 * @returns {void}
	 */
	function createSecretLink() {
		// Remove any existing link first
		const existingLink = document.getElementById("look-inside-link");
		if (existingLink?.parentNode) {
			existingLink.parentNode.removeChild(existingLink);
		}

		// Create the link element
		const link = document.createElement("a");
		link.id = "look-inside-link";
		link.href = "#";
		link.textContent = "look inside";
		link.style.cssText = `
        display: block;
        margin: 4px auto;
        padding: 4px;
        text-align: center;
        font-family: 'IBM Plex Mono', monospace;
        font-size: 10px;
        color: rgba(255, 192, 203, 0.7);
        text-decoration: none;
        transition: color 0.3s ease;
      `;

		// Hover effect
		link.addEventListener("mouseover", () => {
			link.style.color = "#ffc0cb";
		});

		link.addEventListener("mouseout", () => {
			link.style.color = "rgba(255, 192, 203, 0.7)";
		});

		// Click handler
		link.addEventListener("click", (e) => {
			e.preventDefault();
			secretModeActive = toggleSecretMode();
			updateLinkText(secretModeActive);
		});

		// Insert before the footer
		const footer = document.querySelector("footer");
		if (footer) {
			footer.parentNode.insertBefore(link, footer);
		} else {
			// Fallback: add to end of body
			document.body.appendChild(link);
		}

		return link;
	}

	/**
	 * Clean up the secret mode by removing the HTML container and styles.
	 * @returns {void}
	 */
	const cleanup = () => {
		const container = document.getElementById("secret-html-container");
		if (container?.parentNode) {
			container.parentNode.removeChild(container);
		}

		const styleElement = document.getElementById("secret-mode-style");
		if (styleElement?.parentNode) {
			styleElement.parentNode.removeChild(styleElement);
		}

		if (scrollInterval) {
			clearInterval(scrollInterval);
			scrollInterval = null;
		}
	};

	// Clean up first
	cleanup();

	// Create the "look inside" link
	createSecretLink();

	// Return cleanup function for potential future use
	return cleanup;
}

// Initialize the secret mode feature
document.addEventListener("DOMContentLoaded", enableSecretHtmlMode);
